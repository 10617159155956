import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/layout"
import ChainInfo from "../../../views/chain-info"
import { Seo } from "../../../components/seo"
import { getBanner } from "../../../components/utils"
import axios from "axios"
import { reduceValue } from "../../../components/utils"
import { formatBytes } from "../../../components/utils"
import {
  BsCaretDownFill,
  BsCaretUpFill,
  BsHeartPulseFill,
  BsHeartbreakFill,
  BsLockFill,
} from "react-icons/bs"
import Spinner from "../../../components/spinner"

export const Head = ({
  location,
  data: { testChainsJson: chain, allFile: files },
}) => (
  <Seo
    title={`${chain.name} testnet storage providers`}
    description={chain.about}
    pathname={location.pathname}
    image={getBanner(chain.key, files.edges)}
  />
)

const numberFormatter = Intl.NumberFormat()

const Chain = props => {
  const { testChainsJson, allFile } = props.data
  const [podData, setPodData] = React.useState({})
  const [filter, setFilter] = React.useState("")
  const [orderBy, setOrderBy] = React.useState("pod")

  React.useEffect(() => {
    ;(async () => {
      let next_key = null
      let providers = []

      do {
        providers = [
          ...new Set([
            ...providers,
            ...(await axios
              .get(
                `https://testnet-api.jackalprotocol.com/jackal-dao/canine-chain/storage/providers${
                  next_key ? `?pagination.key=${next_key}` : ""
                }`
              )
              .then(response => {
                next_key = response.data.pagination.next_key
                return response.data.providers
              })),
            // .then(providers => providers.map(provider => provider.ip))
          ]),
        ]
      } while (next_key)
      providers.map(provider => {
        const pod = provider.ip
        const result = {
          pod: pod.replace(/^https?:\/\//, ""),
          secure: !!pod.match(/^https:\/\//),
        }
        podData[provider.address] = result
      })
      setPodData({ ...podData })

      const chunkSize = 5
      let chunk = 0
      const chunkedProviders = []
      for (let pod of providers) {
        if (!chunkedProviders[chunk]) {
          chunkedProviders[chunk] = []
        }
        chunkedProviders[chunk].push(pod)
        chunk = (chunk + 1) % chunkSize
      }

      await Promise.allSettled(
        chunkedProviders.map(async providerChunk => {
          const chunkResult = {}
          for (let provider of providerChunk) {
            const pod = provider.ip
            const result = podData[provider.address]
            let live = false
            try {
              const freeSpace = await axios
                .get(
                  `https://testnet-api.jackalprotocol.com/jackal-dao/canine-chain/storage/freespace/${provider.address}`
                )
                .then(response => response.data)
                .then(space => space.space)
                .catch(e => {})
              let space = {
                used_space: parseInt(provider.totalspace) - parseInt(freeSpace),
                total_space: parseInt(provider.totalspace),
              }
              let balance = await axios
                .get(
                  `https://testnet-api.jackalprotocol.com/cosmos/bank/v1beta1/balances/${provider.address}`,
                  { timeout: 10000 }
                )
                .then(response => response.data.balances[0])
                .catch(() => false)

              const files = await axios
                .get(`${pod}/api/data/fids`, { timeout: 3000 })
                .then(response => {
                  live = true
                  return response.data.data
                })
                .catch(() => 0)

              if (!balance && live) {
                balance = await axios
                  .get(`${pod}/api/network/balance`, { timeout: 3000 })
                  .then(response => response.data.balance)
                  .catch(() => false)
              }

              if (!space.used_space && live) {
                const liveSpace = await axios
                  .get(`${pod}/api/client/space`, { timeout: 3000 })
                  .then(response => response.data)
                  .catch(() => false)
                if (liveSpace) space = liveSpace
              }
              result.balance = balance || false
              result.space = space || false
              result.files = Math.floor(files.length / 2)
              result.live = live
            } catch (e) {
              result.balance = false
              result.space = false
              result.files = 0
              result.live = live
            }

            setPodData({ ...podData })
            chunkResult[provider.address] = result
          }
          return chunkResult
        })
      )
    })()
  }, [])

  return (
    <Layout>
      <ChainInfo chain={testChainsJson} data={allFile.edges} />
      <h2>Storage providers</h2>
      <p>
        These are the registered storage providers on the Jackal testnet, and
        their statistics. Any providers with "--" as data could not be reached.
        They are either decommissioned, unreachable or misconfigured.
      </p>
      <input
        type="text"
        value={filter}
        onChange={e => setFilter(e.target.value.toLowerCase())}
        placeholder="Filter"
      ></input>
      <table cellPadding={0} cellSpacing={0}>
        <thead>
          <tr>
            <th onClick={() => setOrderBy("pod")} style={{ cursor: "pointer" }}>
              Pod
              {orderBy === "pod" ? <BsCaretUpFill /> : null}
            </th>
            <th
              onClick={() => setOrderBy("balance")}
              style={{ cursor: "pointer" }}
            >
              Balance
              {orderBy === "balance" ? <BsCaretDownFill /> : null}
            </th>
            <th
              onClick={() => setOrderBy("space")}
              style={{ cursor: "pointer" }}
            >
              Space (used/total)
              {orderBy === "space" ? <BsCaretDownFill /> : null}
            </th>
            <th
              onClick={() => setOrderBy("files")}
              style={{ cursor: "pointer", textAlign: "right" }}
            >
              # files
              {orderBy === "files" ? <BsCaretDownFill /> : null}
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(podData).length ? (
            Object.values(podData)
              .filter(pod => !filter || pod.pod.match(filter))
              .sort((a, b) => {
                switch (orderBy) {
                  case "pod":
                    return a.pod.localeCompare(b.pod)
                  case "balance":
                    return a.balance && b.balance
                      ? b.balance.amount - a.balance.amount
                      : !a.balance
                      ? 1
                      : -1
                  case "space":
                    return a.space && b.space
                      ? b.space.used_space - a.space.used_space
                      : !a.space
                      ? 1
                      : -1
                  case "files":
                    return a.files && b.files
                      ? b.files - a.files
                      : !a.files
                      ? 1
                      : -1
                }
              })
              .map((pod, i) => (
                <tr key={i}>
                  <td>
                    {pod.secure ? <BsLockFill /> : null}{" "}
                    {pod.live === undefined ? null : pod.live ? (
                      <BsHeartPulseFill color="#29870d" />
                    ) : (
                      <BsHeartbreakFill color="#d31515" />
                    )}{" "}
                    {pod.pod}
                  </td>
                  <td>
                    {pod.balance ? (
                      <React.Fragment>
                        {reduceValue(pod.balance.amount, pod.balance.denom)} JKL
                      </React.Fragment>
                    ) : pod.balance === undefined ? (
                      <Spinner />
                    ) : (
                      "--"
                    )}
                  </td>
                  <td>
                    {pod.space ? (
                      <React.Fragment>
                        {formatBytes(pod.space.used_space)}
                        {" / "}
                        {formatBytes(pod.space.total_space)}
                      </React.Fragment>
                    ) : pod.balance === undefined ? (
                      <Spinner />
                    ) : (
                      "--"
                    )}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {pod.files ? (
                      numberFormatter.format(pod.files)
                    ) : pod.balance === undefined ? (
                      <Spinner />
                    ) : (
                      "--"
                    )}
                  </td>
                </tr>
              ))
          ) : (
            <tr>
              <td colSpan={3}>
                <Spinner /> Loading data...
              </td>
            </tr>
          )}
        </tbody>
        {Object.keys(podData).length ? (
          <tfoot>
            <tr>
              <td>
                {
                  Object.values(podData).filter(
                    pod => !filter || pod.pod.match(filter)
                  ).length
                }
                {" / "}
                {Object.values(podData).length} providers
              </td>
              <td>
                {reduceValue(
                  Object.values(podData)
                    .filter(pod => !filter || pod.pod.match(filter))
                    .reduce(
                      (sum, pod) =>
                        sum + (pod.balance ? parseInt(pod.balance.amount) : 0),
                      0
                    ),
                  "ujkl"
                )}{" "}
                JKL
              </td>
              <td>
                {Object.values(podData)
                  .filter(pod => !filter || pod.pod.match(filter))
                  .reduce(
                    (sum, pod) => [
                      sum[0] + (pod.space ? pod.space.used_space : 0),
                      sum[1] + (pod.space ? pod.space.total_space : 0),
                    ],
                    [0, 0]
                  )
                  .map(space => formatBytes(space))
                  .join(" / ")}
              </td>
              <td style={{ textAlign: "right" }}>
                {numberFormatter.format(
                  Object.values(podData)
                    .filter(pod => !filter || pod.pod.match(filter))
                    .reduce((sum, pod) => sum + (pod.files || 0), 0)
                )}
              </td>
            </tr>
          </tfoot>
        ) : null}
      </table>
    </Layout>
  )
}

export default Chain

export const query = graphql`
  query {
    testChainsJson(key: { eq: "jackal" }) {
      id
      about
      stakeUrl
      explorerUrl
      hidden
      key
      logo
      name
      site
      services {
        tmVersion
        gitRepo
        binary
        root
        publicRpc
        publicGrpc
        publicRest
        seedNode
        chainId
        denom
        snapshot
        installation {
          genesisUrl
          addrbookUrl
          seeds
          installScript
          versions {
            gitTag
            name
          }
        }
        stateSync {
          rpc
          peer
        }
        networkMap
      }
    }
    allFile {
      edges {
        node {
          id
          relativePath
          relativeDirectory
          publicURL
        }
      }
    }
  }
`
